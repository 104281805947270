<template>
  <div></div>
</template>

<script>
import { AuthService } from "@/services/auth/authService";

export default {
  beforeRouteEnter(to, from, next) {
    AuthService.clearToken();
    next({ name: "login" });
  },
};
</script>
